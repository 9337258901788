<template>
  <div class="Invoices">
    <v-row justify="space-between">
      <v-col cols="3" class="d-flex mb-2">
        <v-form @submit.prevent="updateList({ search })" class="d-flex">
          <v-text-field
            label="Search By Name or Patient ID"
            outlined
            dense
            :hide-details="true"
            prepend-inner-icon="mdi-magnify"
            v-model="search"
            class="mr-3"
          />
          <v-btn color="primary" type="submit">Search</v-btn>
        </v-form>
      </v-col>

      <v-col cols="3">
        <app-date-picker
          :range="true"
          v-model="dateRange"
          name="date"
          label="Date Range"
        />
      </v-col>

      <v-col cols="3">
        <v-menu offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-btn color="primary" dark v-bind="attrs" v-on="on" outlined dense>
              Is Paid {{ getStatusText }}
            </v-btn>
          </template>
          <v-list>
            <v-list-item v-for="isPaid in isPaidStatuses" :key="isPaid">
              <v-list-item-content @click="updateList({ isPaid })">
                {{ isPaid }}
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-col>

      <v-col cols="12" sm="6" md="3" class="align-end">
        <v-btn
          color="primary"
          dark
          @click="showCreateInvoiceModal = true"
          dense
        >
          <v-icon> mdi-plus </v-icon>
          Create Invoice
        </v-btn>
      </v-col>
    </v-row>

    <v-data-table
      :headers="headers"
      :items="invoices"
      item-key="pid"
      @click:row="goToDetailsPage"
      :loading="fetchInvoicesLoading"
      @update:sort-by="updateSortBy"
      @update:sort-desc="updateSortOrder"
      class="Invoices__clickable"
      :server-items-length="totalInvoicesCount"
      :footer-props="{
        showFirstLastPage: false,
        showCurrentPage: true,
        prevIcon: 'mdi-arrow-collapse-left',
        nextIcon: 'mdi-arrow-collapse-right',
        itemsPerPageOptions,
      }"
      @update:options="updateList"
    >
      <template v-slot:item.Patients.name="{ item }">
        <div>
          {{ item?.['Patients.name'] }}
        </div>
      </template>
      <template v-slot:item.createdAt="{ item }">
        <div class="Invoices__clickable">
          {{ formatDate(item.createdAt) }}
        </div>
      </template>
      <template v-slot:item.isPaid="{ item }">
        <v-chip v-if="item?.isPaid" color="green" outlined text-color="green">
          PAID
        </v-chip>
        <v-chip v-else color="red" outlined text-color="red"> UNPAID </v-chip>
      </template>
      <template v-slot:item.status="{ item }">
        <v-chip outlined>
          {{item.status}}
        </v-chip>
      </template>
    </v-data-table>

    <CreateInvoice
      v-if="showCreateInvoiceModal"
      @close="showCreateInvoiceModal = false"
      :dialog="showCreateInvoiceModal"
      :searchPatient="true"
    />
  </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
import * as moment from 'moment-timezone';
import AppDatePicker from '@/components/app-date-picker/app-date-picker.vue';
import CreateInvoice from '@/components/modals/create-invoice/create-invoice.vue';

const { mapGetters: invoiceGetters, mapActions: invoiceActions } =
  createNamespacedHelpers('invoices');
const { mapGetters: customerGetters } = createNamespacedHelpers('customer');

export default {
  name: 'Invoices',
  components: {
    AppDatePicker,
    CreateInvoice,
  },
  data() {
    return {
      page: 1,
      itemsPerPage: 10,
      limit: 10,
      search: '',
      itemsPerPageOptions: [10, 20, 50, 100],
      isPaid: 'ALL',
      isPaidStatuses: ['PAID', 'UNPAID', 'ALL'],
      dateRange: [
        new Date(Date.now() - 1000 * 60 * 60 * 24 * 365), // one year ago
        new Date(),
      ],
      localTZ: 'UTC',
      sort: '',
      sortOrder: '',
      showCreateInvoiceModal: false,
    };
  },
  watch: {
    'selectedCustomer.customer_id'() {
      this.page = 1;
      this.fetchInvoices(this.getFetchOptions);
    },
    dateRange() {
      this.updateList({});
    },
  },
  computed: {
    ...invoiceGetters({
      invoices: 'INVOICES',
      fetchInvoicesLoading: 'FETCH_INVOICES_LOADING',
      totalInvoicesCount: 'TOTAL_INVOICES_COUNT',
    }),
    ...customerGetters({
      selectedCustomer: 'SELECTED_CUSTOMER',
    }),
    headers() {
      const defaults = { sortable: true, filterable: false };
      return [
        {
          text: 'Name',
          value: 'Patients.name',
          ...defaults,
        },
        {
          text: 'Patient ID',
          value: 'patient_id',
          ...defaults,
        },
        {
          text: 'Invoice No.',
          value: 'visit_id',
          ...defaults,
        },
        {
          text: 'Total Charge (USD)',
          value: 'total_charge',
          ...defaults,
        },
        {
          text: 'Remaining balance (USD)',
          value: 'patient_balance',
          ...defaults,
        },
        {
          text: 'Is Paid',
          value: 'isPaid',
          ...defaults,
        },
        {
          text: 'Status',
          value: 'status',
          ...defaults,
        },
        {
          text: 'Date Created',
          value: 'createdAt',
          ...defaults,
        },
      ];
    },
    getStatusText() {
      if (this.isPaid === 'ALL') return '';
      return `= ${this.isPaid}`;
    },
    getFetchOptions() {
      const option = {
        page: this.page,
        limit: this.itemsPerPage,
        search: this.search,
        fromDate: new Date(
          this.dateRange[0].setHours(23, 59, 59, 999)
        ).toISOString(),
        toDate: new Date(
          this.dateRange[1].setHours(23, 59, 59, 999)
        ).toISOString(),
        sort: this.sort,
        sortOrder: this.sortOrder,
      };

      if (this.isPaid !== 'ALL') {
        option.isPaid = this.isPaid === 'PAID' ? true : false;
      }

      return option;
    },
  },
  created() {
    this.page = this.$route.query?.page ?? 1;
    this.itemsPerPage = this.$route.query?.limit ?? 10;
    this.search = this.$route.query?.search ?? '';
    if (this.$route.query?.isPaid) {
      this.isPaid = this.$route.query?.isPaid === 'true' ? 'PAID' : 'UNPAID';
    }

    if (this.$route.query?.fromDate && this.$route.query?.toDate) {
      this.dateRange = [
        new Date(this.$route.query?.fromDate),
        new Date(this.$route.query?.toDate),
      ];
    }

    this.fetchInvoices(this.getFetchOptions);
  },
  methods: {
    ...invoiceActions(['fetchInvoices']),
    formatDate(date) {
      if (!date) return '';
      else return moment(date).tz(this.localTZ).format('YYYY-MM-DD');
    },
    updateList({ itemsPerPage, page, search, isPaid, sort, sortOrder }) {
      if (itemsPerPage) this.itemsPerPage = itemsPerPage;
      if (page) this.page = page;
      if (search) this.search = search;
      if (isPaid) this.isPaid = isPaid;
      if (sort) this.sort = sort;
      if (sortOrder) this.sortOrder = sortOrder;

      const queryParams = {
        ...this.$route.query,
        page: this.page,
        limit: this.itemsPerPage,
        search: this.search,
        isPaid: this.isPaid === 'PAID' ? true : false,
        fromDate: this.dateRange[0].toISOString(),
        toDate: this.dateRange[1].toISOString(),
        sort: this.sort,
        sortOrder: this.sortOrder,
      };

      if (!this.search) delete queryParams.search;
      if (!this.sort) {
        delete queryParams.sort;
        delete queryParams.sortOrder;
      }
      if (isPaid === null || isPaid === undefined || isPaid === 'ALL')
        delete queryParams.isPaid;

      this.$router.replace({
        path: this.$route.path,
        query: queryParams,
      });

      this.fetchInvoices(this.getFetchOptions);
    },
    goToDetailsPage(invoice) {
      this.$router.push({
        path: `/payments/patients/${invoice.patient_id}?pid=${invoice['Patients.pid']}`,
      });
    },
    updateSortBy(column) {
      this.updateList({ sort: column, sortOrder: 'asc' });
    },
    updateSortOrder(desc = false) {
      if (desc) {
        this.updateList({ sortOrder: 'desc' });
      } else {
        this.updateList({ sortOrder: 'asc' });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import './invoices';
</style>
