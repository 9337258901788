var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-row',{staticClass:"mt-2",attrs:{"justify":"space-between"}},[_c('v-col',{staticClass:"d-flex mb-2",attrs:{"cols":"10"}},[_c('v-form',{staticClass:"d-flex",on:{"submit":function($event){$event.preventDefault();return _vm.getPaymentPlans()}}},[_c('v-text-field',{staticClass:"mr-3",attrs:{"label":"Search Plans By Name or Patient ID","outlined":"","dense":"","hide-details":true,"prepend-inner-icon":"mdi-magnify"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-btn',{attrs:{"color":"primary","type":"submit","loading":_vm.fetchPaymentPlanLoading}},[_vm._v(" Search ")])],1)],1),_c('v-col',{attrs:{"cols":"2"}},[_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"primary","dark":"","outlined":"","dense":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-filter")]),_vm._v(" "+_vm._s(_vm.getStatusText)+" ")],1)]}}])},[_c('v-list',_vm._l((_vm.statuses),function(status){return _c('v-list-item',{key:status},[_c('v-list-item-content',{on:{"click":function($event){return _vm.updateStatus(status)}}},[_vm._v(" "+_vm._s(status)+" ")])],1)}),1)],1)],1)],1),_c('v-data-table',{staticClass:"Plans__clickable",attrs:{"item-key":"id","show-expand":"","headers":_vm.headers,"items":_vm.batches,"loading":_vm.fetchPaymentPlanLoading || _vm.toggleBatchStatusLoading,"single-expand":true,"expanded":_vm.expanded,"server-items-length":_vm.paymentPlansTotal,"footer-props":{
      showFirstLastPage: false,
      showCurrentPage: true,
      prevIcon: 'mdi-arrow-collapse-left',
      nextIcon: 'mdi-arrow-collapse-right',
      itemsPerPageOptions: _vm.itemsPerPageOptions,
    }},on:{"update:sort-by":_vm.updateSortBy,"update:sort-desc":_vm.updateSortOrder,"update:expanded":function($event){_vm.expanded=$event},"click:row":_vm.goToDetailsPage,"update:options":_vm.onPlanPageChange},scopedSlots:_vm._u([{key:"item.next_payment_date",fn:function({ item }){return [_c('div',[_vm._v(" "+_vm._s(_vm.formatDate(item.next_payment_date))+" ")])]}},{key:"item.last_payment_date",fn:function({ item }){return [_c('div',[_vm._v(" "+_vm._s(_vm.formatDate(item.last_payment_date))+" ")])]}},{key:"item.status",fn:function({ item }){return [(item.status === 'active')?_c('v-chip',{attrs:{"color":"green","outlined":"","text-color":"green"}},[_vm._v(" ACTIVE ")]):(item.status === 'disabled')?_c('v-chip',{attrs:{"color":"blue","outlined":"","text-color":"blue"}},[_vm._v(" DISABLED ")]):(item.status === 'cancelled')?_c('v-chip',{attrs:{"color":"red","outlined":"","text-color":"red"}},[_vm._v(" CANCELLED ")]):(item.status === 'paid')?_c('v-chip',{attrs:{"color":"yellow","outlined":"","text-color":"yellow"}},[_vm._v(" PAID ")]):_vm._e()]}},{key:"item.name",fn:function({ item }){return [_c('div',[_vm._v(_vm._s(item.patient.firstname)+" "+_vm._s(item.patient.lastname))])]}},{key:"expanded-item",fn:function({ headers, item }){return [_c('td',{staticClass:"emptybackground",attrs:{"colspan":headers.length}},[_c('v-card',{staticClass:"p-2 ma-2"},[_c('v-data-table',{attrs:{"headers":_vm.invoiceHeaders,"items":item.invoices,"item-key":"id","hide-default-footer":true,"items-per-page":-1},scopedSlots:_vm._u([{key:"item.status",fn:function({ item }){return [(item.charges.every((charge) => charge.statusIsPaid))?_c('v-chip',{attrs:{"outlined":"","color":"green","text-color":"green"}},[_vm._v(" PAID ")]):_c('v-chip',{attrs:{"outlined":"","color":"red","text-color":"red"}},[_vm._v(" UNPAID ")])]}},{key:"item.amount",fn:function({ item }){return [_vm._v(" "+_vm._s(item.charges.reduce((a, c) => a + c.patient_balance, 0.0))+" ")]}}],null,true)})],1)],1)]}}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }