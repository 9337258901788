<template>
  <div>
    <v-tabs v-model="selectedTab" @change="onSelectedTabChange" grow>
      <v-tab v-for="tab in tabs" :key="tab">{{ tab }}</v-tab>
    </v-tabs>

    <v-tabs-items v-model="selectedTab" class="mt-6">
      <v-tab-item>
        <Invoices />
      </v-tab-item>
      <v-tab-item>
        <Plans />
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>

<script>
import Invoices from './invoices/invoices.vue';
import Plans from './plans/plans.vue';

export default {
  components: {
    Invoices,
    Plans,
  },
  data() {
    return {
      tabs: ['invoices', 'plans'],
      selectedTab: 0,
    };
  },
  created() {
    const tab = this.tabs.findIndex((i) => i === this.$route.query?.tab);
    if (tab > -1) {
      this.selectedTab = tab;
    }
  },
  methods: {
    onSelectedTabChange(tab) {
      const activeTab = this.tabs[tab];
      this.$router.push({
        query: {
          tab: activeTab,
        },
      });
    },
  },
};
</script>
