var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"Invoices"},[_c('v-row',{attrs:{"justify":"space-between"}},[_c('v-col',{staticClass:"d-flex mb-2",attrs:{"cols":"3"}},[_c('v-form',{staticClass:"d-flex",on:{"submit":function($event){$event.preventDefault();return _vm.updateList({ search: _vm.search })}}},[_c('v-text-field',{staticClass:"mr-3",attrs:{"label":"Search By Name or Patient ID","outlined":"","dense":"","hide-details":true,"prepend-inner-icon":"mdi-magnify"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-btn',{attrs:{"color":"primary","type":"submit"}},[_vm._v("Search")])],1)],1),_c('v-col',{attrs:{"cols":"3"}},[_c('app-date-picker',{attrs:{"range":true,"name":"date","label":"Date Range"},model:{value:(_vm.dateRange),callback:function ($$v) {_vm.dateRange=$$v},expression:"dateRange"}})],1),_c('v-col',{attrs:{"cols":"3"}},[_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"primary","dark":"","outlined":"","dense":""}},'v-btn',attrs,false),on),[_vm._v(" Is Paid "+_vm._s(_vm.getStatusText)+" ")])]}}])},[_c('v-list',_vm._l((_vm.isPaidStatuses),function(isPaid){return _c('v-list-item',{key:isPaid},[_c('v-list-item-content',{on:{"click":function($event){return _vm.updateList({ isPaid })}}},[_vm._v(" "+_vm._s(isPaid)+" ")])],1)}),1)],1)],1),_c('v-col',{staticClass:"align-end",attrs:{"cols":"12","sm":"6","md":"3"}},[_c('v-btn',{attrs:{"color":"primary","dark":"","dense":""},on:{"click":function($event){_vm.showCreateInvoiceModal = true}}},[_c('v-icon',[_vm._v(" mdi-plus ")]),_vm._v(" Create Invoice ")],1)],1)],1),_c('v-data-table',{staticClass:"Invoices__clickable",attrs:{"headers":_vm.headers,"items":_vm.invoices,"item-key":"pid","loading":_vm.fetchInvoicesLoading,"server-items-length":_vm.totalInvoicesCount,"footer-props":{
      showFirstLastPage: false,
      showCurrentPage: true,
      prevIcon: 'mdi-arrow-collapse-left',
      nextIcon: 'mdi-arrow-collapse-right',
      itemsPerPageOptions: _vm.itemsPerPageOptions,
    }},on:{"click:row":_vm.goToDetailsPage,"update:sort-by":_vm.updateSortBy,"update:sort-desc":_vm.updateSortOrder,"update:options":_vm.updateList},scopedSlots:_vm._u([{key:"item.Patients.name",fn:function({ item }){return [_c('div',[_vm._v(" "+_vm._s(item?.['Patients.name'])+" ")])]}},{key:"item.createdAt",fn:function({ item }){return [_c('div',{staticClass:"Invoices__clickable"},[_vm._v(" "+_vm._s(_vm.formatDate(item.createdAt))+" ")])]}},{key:"item.isPaid",fn:function({ item }){return [(item?.isPaid)?_c('v-chip',{attrs:{"color":"green","outlined":"","text-color":"green"}},[_vm._v(" PAID ")]):_c('v-chip',{attrs:{"color":"red","outlined":"","text-color":"red"}},[_vm._v(" UNPAID ")])]}},{key:"item.status",fn:function({ item }){return [_c('v-chip',{attrs:{"outlined":""}},[_vm._v(" "+_vm._s(item.status)+" ")])]}}])}),(_vm.showCreateInvoiceModal)?_c('CreateInvoice',{attrs:{"dialog":_vm.showCreateInvoiceModal,"searchPatient":true},on:{"close":function($event){_vm.showCreateInvoiceModal = false}}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }