<template>
  <div>
    <v-row justify="space-between" class="mt-2">
      <v-col cols="10" class="d-flex mb-2">
        <v-form @submit.prevent="getPaymentPlans()" class="d-flex">
          <v-text-field
            label="Search Plans By Name or Patient ID"
            outlined
            dense
            :hide-details="true"
            prepend-inner-icon="mdi-magnify"
            v-model="search"
            class="mr-3"
          />
          <v-btn
            color="primary"
            type="submit"
            :loading="fetchPaymentPlanLoading"
          >
            Search
          </v-btn>
        </v-form>
      </v-col>
      <v-col cols="2">
        <v-menu offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-btn color="primary" dark v-bind="attrs" v-on="on" outlined dense>
              <v-icon left>mdi-filter</v-icon>
              {{ getStatusText }}
            </v-btn>
          </template>
          <v-list>
            <v-list-item v-for="status in statuses" :key="status">
              <v-list-item-content @click="updateStatus(status)">
                {{ status }}
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-col>
    </v-row>

    <v-data-table
      item-key="id"
      class="Plans__clickable"
      show-expand
      :headers="headers"
      :items="batches"
      :loading="fetchPaymentPlanLoading || toggleBatchStatusLoading"
      :single-expand="true"
      @update:sort-by="updateSortBy"
      @update:sort-desc="updateSortOrder"
      :expanded.sync="expanded"
      @click:row="goToDetailsPage"
      :server-items-length="paymentPlansTotal"
      :footer-props="{
        showFirstLastPage: false,
        showCurrentPage: true,
        prevIcon: 'mdi-arrow-collapse-left',
        nextIcon: 'mdi-arrow-collapse-right',
        itemsPerPageOptions,
      }"
      @update:options="onPlanPageChange"
    >
      <template v-slot:item.next_payment_date="{ item }">
        <div>
          {{ formatDate(item.next_payment_date) }}
        </div>
      </template>

      <template v-slot:item.last_payment_date="{ item }">
        <div>
          {{ formatDate(item.last_payment_date) }}
        </div>
      </template>

      <template v-slot:item.status="{ item }">
        <v-chip
          v-if="item.status === 'active'"
          color="green"
          outlined
          text-color="green"
        >
          ACTIVE
        </v-chip>
        <v-chip
          v-else-if="item.status === 'disabled'"
          color="blue"
          outlined
          text-color="blue"
        >
          DISABLED
        </v-chip>
        <v-chip
          v-else-if="item.status === 'cancelled'"
          color="red"
          outlined
          text-color="red"
        >
          CANCELLED
        </v-chip>
        <v-chip
          v-else-if="item.status === 'paid'"
          color="yellow"
          outlined
          text-color="yellow"
        >
          PAID
        </v-chip>
      </template>

      <template v-slot:item.name="{ item }">
        <div>{{ item.patient.firstname }} {{ item.patient.lastname }}</div>
      </template>

      <template v-slot:expanded-item="{ headers, item }">
        <td :colspan="headers.length" class="emptybackground">
          <v-card class="p-2 ma-2">
            <v-data-table
              :headers="invoiceHeaders"
              :items="item.invoices"
              item-key="id"
              :hide-default-footer="true"
              :items-per-page="-1"
            >
              <template v-slot:item.status="{ item }">
                <v-chip
                  v-if="item.charges.every((charge) => charge.statusIsPaid)"
                  outlined
                  color="green"
                  text-color="green"
                >
                  PAID
                </v-chip>
                <v-chip v-else outlined color="red" text-color="red">
                  UNPAID
                </v-chip>
              </template>
              <template v-slot:item.amount="{ item }">
                {{ item.charges.reduce((a, c) => a + c.patient_balance, 0.0) }}
              </template>
            </v-data-table>
          </v-card>
        </td>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
import * as moment from 'moment';

const { mapGetters: invoiceGetters, mapActions: invoiceActions } =
  createNamespacedHelpers('invoices');
const { mapGetters: customerGetters } = createNamespacedHelpers('customer');
export default {
  data() {
    return {
      search: '',
      expanded: [],
      invoiceExpanded: [],
      paymentPlanCurrentPage: 1,
      paymentPlanCurrentItemsPerPage: 10,
      dialog: false,
      status: 'Active',
      statuses: ['All', 'Active', 'Disabled', 'Paid', 'Cancelled'],
      itemsPerPageOptions: [10, 20, 50, 100],
      sort: '',
      sortOrder: '',
    };
  },
  computed: {
    ...invoiceGetters({
      paymentPlans: 'PAYMENT_PLANS',
      paymentPlansTotal: 'PAYMENT_PLANS_TOTAL',
      standaloneInvoices: 'STANDALONE_INVOICES',
      standaloneInvoicesTotal: 'STANDALONE_INVOICES_TOTAL',
      fetchPaymentPlanLoading: 'FETCH_PAYMENT_PLAN_LOADING',
      standaloneInvoicesLoading: 'STANDALONE_INVOICES_LOADING',
      toggleBatchStatusLoading: 'TOGGLE_BATCH_STATUS_LOADING',
    }),
    ...customerGetters({
      selectedCustomer: 'SELECTED_CUSTOMER',
    }),
    differentPatientInvoicesError() {
      return (
        new Set(this.selectedInvoices.map((invoice) => invoice.patient?.id))
          .size > 1
      );
    },
    getStatusText() {
      return this.status;
    },
    invoices() {
      return this.standaloneInvoices;
    },
    batches() {
      return this.paymentPlans;
    },
    pid() {
      return this.paymentPlans?.id ?? null;
    },
    invoiceHeaders() {
      const defaults = { sortable: false, filterable: false };
      return [
        {
          text: 'Invoice id',
          value: 'id',
          ...defaults,
        },
        {
          text: 'Amount',
          value: 'amount',
          ...defaults,
        },
        {
          text: 'Status',
          value: 'status',
          ...defaults,
        },
      ];
    },
    headers() {
      const defaults = { sortable: true, filterable: false };
      return [
        {
          text: 'Patient Id',
          value: 'patient.patient_id',
          ...defaults,
        },
        {
          text: 'Name',
          value: 'name',
          ...defaults,
        },
        {
          text: 'Balance',
          value: 'balance',
          ...defaults,
        },
        {
          text: 'Last Payment Date',
          value: 'last_payment_date',
          ...defaults,
        },
        {
          text: 'Next Payment Date',
          value: 'next_payment_date',
          ...defaults,
        },
        {
          text: 'Status',
          value: 'status',
          ...defaults,
        },
      ];
    },
  },
  watch: {
    'selectedCustomer.customer_id'() {
      this.paymentPlanCurrentPage = 1;
      this.getPaymentPlans();
    },
  },
  methods: {
    ...invoiceActions(['fetchPaymentPlans', 'toggleBatchStatus']),
    onPlanPageChange(options) {
      this.paymentPlanCurrentPage = options.page;
      this.paymentPlanCurrentItemsPerPage = options.itemsPerPage;
      this.getPaymentPlans();
    },
    toggleDialog() {
      this.dialog = !this.dialog;
    },
    updateStatus(status) {
      this.status = status;
      this.getPaymentPlans();
    },
    formatDate(date) {
      if (!date) return '';
      return moment(date).format('YYYY-MM-DD');
    },
    async getPaymentPlans() {
      this.fetchPaymentPlans({
        search: this.search,
        status:
          this.status.toLowerCase() === 'all'
            ? null
            : this.status.toLowerCase(),
        limit: this.paymentPlanCurrentItemsPerPage,
        page: this.paymentPlanCurrentPage,
        sort: this.sort,
        sortOrder: this.sortOrder,
      });
    },
    async refreshPage() {
      this.selectedInvoices = [];
      await this.getPaymentPlans();
    },
    async toggleBatch(batchId) {
      await this.toggleBatchStatus(batchId);
      await this.getPaymentPlans();
    },
    goToDetailsPage(batch) {
      this.$router.push({
        path: `/payments/patients/${batch.patient.patient_id}?pid=${batch.patient.id}`,
      });
    },
    updateSortBy(column) {
      this.sort = column;
      this.sortOrder = 'asc';
      this.getPaymentPlans();
    },
    updateSortOrder(desc = false) {
      this.sortOrder = desc ? 'desc' : 'asc';
      this.getPaymentPlans();
    },
  },
};
</script>
<style scoped lang="scss">
@import './plans';
</style>
